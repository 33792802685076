import { isIntegerAndZero, isMoney, numMaxConstruct } from '@/utils'

export const formOption = {
  menuBtn: false,
  span: 10,
  labelWidth: 140,
  column: [
    {
      label: '中文名称',
      prop: 'chineseName',
      placeholder: '单行输入',
      rules: [
        {
          required: true,
          message: '中文名称必填'
        }
      ]
    },
    {
      label: '英文名称',
      prop: 'englishName',
      placeholder: '单行输入',
      rules: [
        {
          required: true,
          message: '英文名称必填'
        }
      ]
    },
    {
      label: '申报金额（USD）',
      prop: 'declaredAmount',
      placeholder: '单行输入',
      rules: [
        {
          required: true
        },
        {
          validator: isMoney
        },
        {
          validator: numMaxConstruct(99999999.99)
        }
      ]
    },
    {
      label: '申报重量（g）',
      prop: 'declaredWeight',
      placeholder: '单行输入',
      rules: [
        {
          required: true
        },
        {
          validator: isIntegerAndZero
        }
      ]
    },
    {
      label: '材质 (英文)',
      prop: 'declaredMaterial',
      placeholder: '单行输入',
      rules: [
        {
          required: true,
          message: '材质(英文)必填'
        }
      ]
    },
    {
      label: '海关编码',
      prop: 'customsCode',
      placeholder: '单行输入',
      rules: [
        {
          required: true
        }
      ]
    },
    {
      label: '用途',
      prop: 'purpose',
      placeholder: '单行输入'
    },
    {
      label: '报关属性',
      prop: 'declaration',
      type: 'checkbox',
      placeholder: '单行输入',
      span: 24
    }
  ]
}
